import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Image from '../../elements/Image';

import logoImage from '../../../assets/images/logo.svg'

const Logo = ({
  className,
  ...props
}) => {

  const classes = classNames(
    'brand',
    className
  );

  return (
    <div
      {...props}
      className={classes}
    >
      <h1 className="m-0">
        <Image
              src={logoImage}
              alt="Open"
              width={450}
              height={450} />
        </h1>
    </div>
  );
}

export default Logo;