import React, { useEffect } from 'react';
import { Form, Field } from "@progress/kendo-react-form";
import PropTypes from 'prop-types';
import classNames from 'classnames';
import AWS from 'aws-sdk';

const propTypes = {
  children: PropTypes.node,
  handleClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  closeHidden: PropTypes.bool,
  video: PropTypes.string,
  videoTag: PropTypes.oneOf(['iframe', 'video'])
}

const defaultProps = {
  children: null,
  show: false,
  closeHidden: false,
  video: '',
  videoTag: 'iframe'
}

const Modal = ({
  className,
  children,
  handleClose,
  show,
  closeHidden,
  video,
  videoTag,
  ...props
}) => {

  useEffect(() => {
    document.addEventListener('keydown', keyPress);
    document.addEventListener('click', stopProgagation);
    return () => {
      document.removeEventListener('keydown', keyPress);
      document.removeEventListener('click', stopProgagation);
    };    
  });

  useEffect(() => {
    handleBodyClass();
  }, [props.show]); 
  
  const handleBodyClass = () => {
    if (document.querySelectorAll('.modal.is-active').length) {
      document.body.classList.add('modal-is-active');
    } else {
      document.body.classList.remove('modal-is-active');
    }
  }

  const keyPress = (e) => {
    e.keyCode === 27 && handleClose(e);
  }

  const stopProgagation = (e) => {
    e.stopPropagation();
  }

  const classes = classNames(
    'modal',
    show && 'is-active',
    video && 'modal-video',
    className
  );

  const bucketName = process.env.REACT_APP_QUOTE_BUCKET;
  const filePath = process.env.REACT_APP_QUOTE_FOLDER;

  var s3 = new AWS.S3({
    apiVersion: '2006-03-01',
    params: {Bucket: bucketName}
  });

  const Input = (fieldProps) => {
    const {
      fieldType, name, label, value, placeholder, visited, touched, valid,
      onChange, onBlur, onFocus, validationMessage 
    } = fieldProps;
    const invalid = !valid && visited;
    return (
      <div onBlur={onBlur} onFocus={onFocus}>
        <label className="modal-label">
          { label }
        </label>
        <div>
        <input
            name={name}
            type={fieldType}
            placeholder={placeholder}
            className={invalid ? "invalid" : ""}
            value={value}
            onChange={onChange} />
        </div>
        { invalid && 
          (<div className="modal-required">{validationMessage}</div>) }
      </div>
    );
  };

  const DropDown = ({ label, value, valid, visited, options,
    onChange, onBlur, onFocus, validationMessage }) => {
    const invalid = !valid && visited;
    return (
      <div onBlur={onBlur} onFocus={onFocus}>
        <label className="modal-label">
          { label }
        </label>
        <div>
          <select defaultValue={value} onChange={onChange}>
            <option value="Automotive">Automotive</option>
            <option value="Homeowners">Homeowners</option>
            <option value="Flood">Flood</option>
            <option value="Umbrella">Umbrella</option>
            <option value="BusinessOwners">Business owners</option>
          </select>
        </div>
        { invalid && 
          (<div className="modal-required">{validationMessage}</div>) }
      </div>
    )
  }

  const emailValidator = (value) => (
    new RegExp(/\S+@\S+\.\S+/).test(value) ? "" : "Please enter a valid email."
  );

  const phoneNumberValidator = (value) => (
    new RegExp(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/).test(value) ? "" : "Please enter a valid phone number."
  );

  const requiredValidator = (value) => {
    return value ? "" : "This field is required";
  }

  const FormComponent = () => {
    const handleSubmit = (data, event) => {
      event.preventDefault();

      const message = `
        ${data.name} has requested a quote
        Email: ${data.email}
        Phone: ${data.phoneNumber}
        Insurance Type: ${data.insuranceType}
      `

      s3.putObject({
        Key: filePath,
        Body: message,
        ACL: 'public-read'
        }, function(err, data) {
    
          handleClose(event);
          // alert.show('We have recieved your request, Sal will give you a call for an accurate quote!')
          console.log(data);
      });
    }

    return (
      <div className="is-boxed" onClick={stopProgagation}>
            <Form
              onSubmit={handleSubmit}
              initialValues={{name: "", email: "", phoneNumber: "", insuranceType: "Automotive"}}
              render={(formRenderProps) => (
                <form onSubmit={formRenderProps.onSubmit}>
                  <h1>Request a quote</h1>

                  <div className="modal-content">
                  <Field 
                      label="Full name:"
                      name="name"
                      component={Input}
                      validator={[requiredValidator]} />

                    <Field
                      label="Email:"
                      name="email"
                      fieldType="email"
                      component={Input} />

                    <Field
                      label="Phone number:"
                      name="phoneNumber"
                      placeholder="(123)456-7890"     
                      component={Input}
                      validator={[requiredValidator,phoneNumberValidator]} />

                    <Field
                      label="Policy type:"
                      name="insuranceType"  
                      component={DropDown}
                      fieldRequired={false}
                     /> 

                    <button className="modal-button" type="submit" disabled={!formRenderProps.allowSubmit}>
                                Request quote
                    </button>
                  </div>
                </form>
              )}>
            </Form>
          </div>
        )
  }

  return (
    <>
      {show &&
        <div
          {...props}
          className={classes}
          onClick={handleClose}
        >
          <FormComponent />
        </div>
      }
    </>
  )
}

Modal.propTypes = propTypes;
Modal.defaultProps = defaultProps;

export default Modal;