import React from 'react';
import Button from '../components/elements/Button';
import { 
    FormControl, 
    InputLabel, 
    Input,
    TextField,
    MenuItem
} from '@mui/material';

const RequestQuote = () => {
    const insuranceTypes = [
        {
          value: 'Automotive',
          label: 'Automotive',
        },
        {
          value: 'Homeowners',
          label: 'Homeowners',
        },
        {
          value: 'Flood',
          label: 'Flood',
        },
        {
          value: 'Umbrella',
          label: 'Umbrella',
        },
        {
            value: 'Business Owners',
            label: 'Business Owners',
        }
      ];

  return (
    <>
        <section className='hero section center-content'>
            <div className="container-sm">
                <div className='hero-inner section-inner'>
                    <div className="hero-content">
                        <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
                            Request a quote
                        </h1>
                    </div>
                    <form style={{display: 'flex', 'flex-direction': 'column', gap: '41px', padding: '17px'}} className='w-full container content-center' action="https://api.web3forms.com/submit" method="POST">
                        <input type='hidden' name='access_key' value='71c6e972-d4ea-4b4f-97fd-dcbdf5462e9f'/>
                        <input type="hidden" name="redirect" value="https://lobueandson.com/success" />
                        <input type="checkbox" name="botcheck" className="hidden" style={{display: 'none'}}/>
                        <input type="hidden" name="from_name" value="Quote Request - LoBue & Son Webpage"/>

                        <FormControl>
                            <InputLabel required htmlFor="name-input">Full name</InputLabel>
                            <Input required name='name' id="name-input"/>
                        </FormControl>
                        <FormControl> 
                            <InputLabel required htmlFor="email-input">Email address</InputLabel>
                            <Input required name='email' id="email-input"/>    
                        </FormControl>
                        <FormControl> 
                            <InputLabel required htmlFor="phone-input">Callback number</InputLabel>
                            <Input required name='phone' id="phone-input"/>    
                        </FormControl>
                        <FormControl>
                        <TextField
                            id="outlined-select-currency"
                            select
                            name='Policy type'
                            label="Select"
                            defaultValue="Automotive"
                            helperText="Please select your policy type"
                            >
                            {insuranceTypes.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                {option.label}
                                </MenuItem>
                            ))}
                            </TextField>
                        </FormControl>
                        <FormControl>
                            <TextField
                                multiline
                                minRows={4}
                                id="message-input"
                                name="Additional information"
                                helperText='Optional'
                                label="Are there any additional details you may provide? (Car accidents, current policy, etc.)"
                            />
                        </FormControl>
                        <div className="h-captcha" data-captcha="true"></div>
                        <Button type='submit' color="dark">Submit quote inquiry</Button>
                    </form>
                </div>
            </div>
        </section>
    </>
  );
}

export default RequestQuote;