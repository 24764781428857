import React from 'react';
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { createHashHistory } from "history";

import App from './App';
import * as serviceWorker from './serviceWorker';

//import './App.css';
import './assets/scss/style.scss';

const history = createHashHistory();

// // optional configuration
// const options = {
//   // you can also just use 'bottom center'
//   position: positions.TOP_CENTER,
//   timeout: 5000,
//   offset: '10px',
//   type: 'info',
//   // you can also just use 'scale'
//   transition: transitions.SCALE
// }

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter history={history}>
    <App />
  </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
