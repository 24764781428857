const Success = () => {

  return (
    <>
        <section className='hero section center-content'>
            <div className="container-sm">
                <div className='hero-inner section-inner'>
                    <div className="hero-content">
                        <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
                            Thank you for your inquiry. Sal will get back to you as soon as possible!
                        </h1>
                    </div>
                </div>
            </div>
        </section>
    </>
  );
}

export default Success;