import React, { useRef, useEffect } from 'react';
import { useLocation, Routes, Route } from 'react-router-dom';
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';
import ReactGA from 'react-ga';
import AWS from 'aws-sdk';

// Layouts
import LayoutDefault from './layouts/LayoutDefault';

// Views 
import Home from './views/Home';
import RequestQuote from './views/RequestQuote';
import Success from './views/Success';

// Initialize Google Analytics
ReactGA.initialize(process.env.REACT_APP_GA_CODE);

// Config aws
const buckerRegion = process.env.REACT_APP_AWS_REGION;
const identityPoolId = process.env.REACT_APP_IDENTITY_POOL_ID;

AWS.config.region = buckerRegion; // Region
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: identityPoolId,
});

const trackPage = page => {
  ReactGA.set({ page });
  ReactGA.pageview(page);
};

const App = () => {

  const childRef = useRef();
  let location = useLocation();

  useEffect(() => {
    const page = location.pathname;
    document.body.classList.add('is-loaded')
    childRef.current.init();
    trackPage(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <ScrollReveal
      ref={childRef}
      children={() => (
        <Routes>
          <Route exact path="/" element={<AppRoute component={Home} layout={LayoutDefault}/>}/>
          <Route exact path="/contact" element={<AppRoute component={RequestQuote} layout={LayoutDefault}/>}/>
          <Route exact path="/success" element={<AppRoute component={Success} layout={LayoutDefault}/>}/>
        </Routes>
      )} />
  );
}

export default App;